import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

// Connects to data-controller="copy-paste"
export default class extends Controller {
  static values = {
    pairs: Object
  }

  connect() {
    Object.entries(this.pairsValue).forEach(([source, target]) => {
      $(source).change(function() {
        $(target).val($(this).val());
        $(target).trigger('change');
      });
    })
  }
}
