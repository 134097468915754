/* eslint no-console:0 */

import { Turbo } from "@hotwired/turbo-rails"
import "./controllers"

import "./stylesheets/application"
import "./vendor"

import $ from 'jquery';
import EnterToSearch              from './src/enter_to_search'
import AttachHumanSize            from './src/attach_human_size'
import AttachHumanDate            from './src/attach_human_date'
import AttachCollapsibleContainer from './src/attach_collapsible_container'
import MultiSelect                from './src/multi_select'

document.addEventListener('turbo:load', function() {
  //console.log("event turbo:load")
  $(document).foundation()

  EnterToSearch.init()
  AttachHumanSize.init()
  AttachHumanDate.init()
  AttachCollapsibleContainer.init()
  new MultiSelect().init()
})

document.addEventListener('turbo:before-cache', function() {
  //console.log("event turbo:before-cache")
})

document.addEventListener('turbo:before-render', function(event) {
  //console.log("event turbo:before-render")
  //console.log(event)
})
