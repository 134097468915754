import { Controller } from "@hotwired/stimulus"

// Taken from https://github.com/stimulus-components/stimulus-checkbox-select-all/blob/master/src/index.ts
// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static targets = ['checkboxAll', 'checkbox']

  initialize () {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggle)
    this.refresh()
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.refresh)
    this.refresh()
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.toggle)
    this.refresh()
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.refresh)
    this.refresh()
  }

  toggle(e) {
    e.preventDefault()

    this.checkboxTargets
      .filter(checkbox => !checkbox.disabled)
      .forEach(checkbox => {
        checkbox.checked = e.target.checked
        this.triggerInputEvent(checkbox)
      })
  }

  refresh() {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }

  triggerInputEvent(checkbox) {
    const event = new Event('input', { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }
}
