import $ from 'jquery';

export default class MultiSelect {
  // getElementById only works if root = document!
  constructor(root = document) {
    this.root = root
  }

  init() {
    this.initSelectAll()
    this.initClickTargetButton()
  }

  initSelectAll() {
    const allEl = this.root.getElementById('select_all')
    if (allEl) {
      allEl.addEventListener('click', e => {
        this.root.querySelectorAll('input.row_checkbox').forEach(el => {
          el.checked = allEl.checked
        })
      })
    }
  }

  initClickTargetButton() {
    this.root.querySelectorAll('.selection-target-button').forEach(targetButton => {
      targetButton.addEventListener('click', e => {
        e.preventDefault()

        const idsSelector = targetButton.dataset.forceAll ? '.row_checkbox' : '.row_checkbox:checked'
        const ids = Array.from(this.root.querySelectorAll(idsSelector)).map(el => el.id)

        const idsOptional = String(targetButton.dataset.idsOptional) === 'true'
        if (!idsOptional && !ids.length) {
          alert('Nothing selected')
          return false
        }

        const idsStructure = JSON.parse(targetButton.dataset.idsStructure) || { ids: [] }
        if (!this.setIdsInObject(idsStructure, ids)) {
          alert('Cannot set ids on given target structure')
          return false
        }

        let target = targetButton.dataset.target || window.location.pathname
        target += target.indexOf('?') > -1 ? '&' : '?'
        target += $.param(idsStructure)

        window.location.assign(target)
        return false
      })
    })
  }

  setIdsInObject(object, ids) {
    let idsAreSet = false
    for (const k in object) {
      const v = object[k]
      if ((v.constructor === Array) && (v.length === 0)) {
        object[k] = ids
        idsAreSet = true
        break
      } else if ((v === 'csv_ids') || (v === 'csv_placeholder')) {
        object[k] = ids.join(',')
        idsAreSet = true
        break
      } else if (typeof v === 'object') {
        return this.setIdsInObject(v, ids)
      }
    }
    return idsAreSet
  }
}
